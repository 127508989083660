import { clsx } from 'clsx';
import Partials from 'components/partials/Partials';
import type { PartialWidgetContext } from 'types/cms';
import type { PropsWithClassName } from 'types/react-props';
import styles from './group-grid-section.module.scss';

export type GroupGridSectionProps = PropsWithClassName<{
  readonly children: Array<PartialWidgetContext>;
}>;

const GroupGridSection = ({ context }: { context: GroupGridSectionProps }) => (
  <div className={clsx(styles.grid, context.className)}>
    {context.children.map((widget: PartialWidgetContext) => (
      <Partials key={widget.id} context={widget} />
    ))}
  </div>
);

export default GroupGridSection;
